import { graphql, useStaticQuery } from "gatsby"
import React from "react"

const ProductList = () => {
  const data = useStaticQuery(graphql`
    query {
      catalog: allMarkdownRemark(
        filter: { fileAbsolutePath: { regex: "//catalog//" } }
        sort: { fields: frontmatter___priority, order: ASC }
      ) {
        edges {
          node {
            fields {
              id
            }
            frontmatter {
              type
              title
              active
            }
          }
        }
      }
    }
  `)

  return (
    <ul className="inline-list products">
      {data.catalog.edges
        .filter(a => a.node.frontmatter.active)
        .map((edge, i) => {
          const catalog = edge.node
          return (
            <li className={`product`} key={`product-catalog-${i}`}>
              <a
                className="product-link"
                href={`/products/${catalog.fields.id}`}
              >
                <div className={`icon product ${catalog.fields.id}`} />
                <p className="label">{catalog.frontmatter.title}</p>
              </a>
            </li>
          )
        })}
    </ul>
  )
}

export default ProductList

import React from "react"


import Layout from "../components/layout"
import ProductList from "./product-list"


export default () => {
  console.log("Page Context");

  return (
    <Layout>
      <h1>All Products</h1>
      <ProductList/>
    </Layout>
  )
}

